<template>
    <div>
        <img ref="logo" id="logo" :class="logoClass" src="../assets/logo_rect.jpg" alt="PLint-sites logo rectangular">
        <form class="login" @submit.prevent="loginAndRedirect">
            <div id="loginform" :class="loginFormClass">
                <div class="form-group row">
                    <label for="inputEmail" class="col-sm-4 col-form-label col-form-label-sm">E-mailadres</label>
                    <div class="col-sm-8">
                        <input required @focus="shrinkLogo" v-model="email" type="email" placeholder="Email" id="inputEmail" class="form-control" autocomplete="off"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="inputPassword" class="col-sm-4 col-form-label col-form-label-sm">Wachtwoord</label>
                    <div class="col-sm-8">
                        <input required v-model="password" type="password" placeholder="Password" id="inputPassword" class="form-control"/>
                    </div>
                </div>
                <p class="form-error" v-if="loginError" v-text="loginErrorMessage"></p>
            </div>
            <button class="btn btn-primary" type="submit"><i class="fa fa-user"></i> Inloggen</button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Login',
    data() {
        return {
            // email: (process.env.NODE_ENV === 'development' ? 'pim@plint-sites.nl' : ''),
            // password: (process.env.NODE_ENV === 'development' ? '1234abcd' : ''),
            email: '',
            password: '',
            logoClass: '',
            loading: false,
            loginError: null,
            loginErrorMessage: '',
        }
    },
    computed: {
        loginFormClass() {
            if (this.loading) {
                return 'loading'
            }
            return this.loginError ? 'error' : 'success'
        },
    },
    methods: {
        ...mapActions('user', ['login']),
        async loginAndRedirect() {
            this.loading = true

            try {
                await this.login(this)
                if (this.$route.query.redirect !== undefined) {
                    this.$router.push(this.$route.query.redirect)
                } else {
                    this.$router.push('/dashboard')
                }
            } catch (error) {
                if (error.response && error.response.status) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 422) {
                        this.loginErrorMessage = Object.values(error.response.data.errors)
                        .map(formFieldErrorArray => formFieldErrorArray.join(', '))
                        .join('')
                    } else if (error.response.status === 403) {
                        this.loginErrorMessage = 'Deze actie is ongeldig'
                    } else if (error.response.status === 500) {
                        this.loginErrorMessage = 'Oeps, een bug in de PLint App'
                    }
                } else {
                    this.loginErrorMessage = 'Inloggen is niet gelukt'
                }
                this.loginError = true
            } finally {
                this.loading = false
            }
        },
        shrinkLogo() {
            this.logoClass = 'shrink'
        },
    },
};
</script>

<style scoped lang="less">
#logo {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    margin-top: -24px;
    transition: transform 0.5s;

    &.shrink {
        transform: scale(0.5) translateY(-96px);
        margin-bottom: 0;
        margin-top: 0;
    }
}

#loginform {
    padding: 24px;
    background: @background2;
    border: 2px solid @background1;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    transition: margin 0.5s;

    &.shrink {
        margin-top: -48px;
    }

    label {
        text-align: left;
    }

    .form-group {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    .form-control:focus {
        box-shadow: 0 0 0 0.2rem #dcefdd;
    }

    /* loading styles */
    &.loading {
        background: @background2;
        border-color: @primary;
        border-style: dashed;
        border-width: 2px;
    }

    /* error styles */
    &.error {
        background: #f7d6d4;
        border-color: @secondary;

        .form-control {
            border-color: #f5c9c7;
            &:focus {
                box-shadow: 0 0 0 0.2rem #fae2e1;
            }
        }

        .form-error {
            color: @secondary;
            margin-bottom: 0;
            font-size: .875rem;
            line-height: 24px;
        }
    }
}

button[type=submit] {
    width: 100%;
}
</style>
